import { config } from '../../../config'
import { I18n } from './I18n'
import { moment } from './moment'

const { browserLanguage } = config.__app__

const changeLanguage =
  (lang) =>
  ({ default: locale }) =>
    new Promise((resolve) => {
      moment.locale(lang)
      I18n.addResourceBundle(lang, 'translation', locale)
      I18n.changeLanguage(lang, resolve)
    })

export const loadLocales = (lang = browserLanguage) => Promise.resolve()
