/**
 * This file is imported before optional polyfills
 */
import { LOG_LEVEL } from '@panda/core/store/middleware/createLogger'
import { I18n } from './modules/common/locales/I18n'

/**
 * type reduxLogger = {
 *   actionType: string,
 *   logLevel: string,
 *   module: string,
 * }
 */
const reduxLogger = {
  actionType: 'analytics/ADD_EVENT',
  logLevel: LOG_LEVEL.ALL,
  module: 'analytics',
  eventValidation: false,
}

const appVersion = APP_VERSION

const appTranslations = ['en']
const webTranslations = ['en'] // info.tellnonym.me
window.tnym.getLanguage = () => I18n.language.toLowerCase().replace(/-\w\w/, '')
const lang = window.tnym.getLanguage()
const browserLanguage = appTranslations.indexOf(lang) > -1 ? lang : 'en'
const webLanguage = webTranslations.indexOf(lang) > -1 ? lang : 'en'

export const config = {
  __app__: {
    appTranslations,
    appVersion,
    browserLanguage,
    webLanguage,
  },
  analytics: {
    registered: {
      endpoint: 'analytics/track',
      threshold: 1,
    },
  },
  api: {
    client: `web:${appVersion}`,
    errorBlacklist: [],
    homepage: 'https://dontcare.app',
    host: 'https://api.dontcare.app',
    hostAnalytics: 'https://api.dontcare.app',
    isWeb: true,
    limit: window.tnym.isDesktop() ? 24 : 12,
    maxAnswerImageSize: 5000000,
    maxAvatarSize: 500000,
    updateInterval: process.env.REACT_APP_ENV === 'development' ? 60000 : 30000,
  },
  DeviceInfo: {
    deviceName: window.navigator.userAgent.substring(0, 100),
    deviceType: 'web',
    lang: webLanguage,
  },
  doNotDisturbRoutes: ['/'],
  externalLinks: {
    appstore: 'https://itunes.apple.com/us/app/id6451150038',
  },
  helpers: {},
  reduxLogger,
  Sentry: {
    config: {
      autoSessionTracking: false,
      debug: process.env.REACT_APP_ENV === 'development',
      dsn: 'https://45e973492a354ed7a1c0419956bb2a0c@o74781.ingest.sentry.io/4505522739216384',
      enabled: process.env.REACT_APP_ENV !== 'development',
      environment: process.env.REACT_APP_ENV,
      release: appVersion,
      sampleRate: process.env.REACT_APP_ENV === 'development' ? 1.0 : 0.5,
      allowUrls: [
        /https?:\/\/(cdn|www|)\.dontcare\.app/,
        /https?:\/\/unpkg.com/,
        /http:\/\/localhost:3000/,
      ],
      denyUrls: [
        /extensions\//i,
        /^chrome:\/\//i,
        /^.*pageFold\/ftpagefold_v.*\.js$/,
        /^https:\/\/raw\.githubusercontent\.com\/ampproject\/amphtml\/.*\/ads\/.*$/,
        /^\/diff\/templates\/ts\/dist\/viewability\/.*\.js$/,
      ],
      ignoreErrors: [
        '[undefined]: [undefined]',
        /\[undefined\]/,
        /Failed to fetch/,
        /Image is not a constructor/,
        /^Failed to load.*Document is already detached\.$/,
      ],
    },
    /**
     * string: excludes error if e.stack.includes(string) equals true
     * regex: excludes error if regex.test(e.stack) equals true
     */
    stackBlacklist: [],
  },
  store: {
    offline: {},
    version: '0.1.0',
  },
  WebViews: {
    Privacy: 'https://dontcare.app/privacy',
    Terms: 'https://dontcare.app/terms',
  },
}
