import ar from '@panda/strings/app/ar.json'
import de from '@panda/strings/app/de.json'
import en from '@panda/strings/app/en.json'
import es from '@panda/strings/app/es.json'
import fr from '@panda/strings/app/fr.json'
import hi from '@panda/strings/app/hi.json'
import id from '@panda/strings/app/id.json'
import ja from '@panda/strings/app/ja.json'
import ko from '@panda/strings/app/ko.json'
import ms from '@panda/strings/app/ms.json'
import pt from '@panda/strings/app/pt.json'
import ru from '@panda/strings/app/ru.json'
import th from '@panda/strings/app/th.json'
import tr from '@panda/strings/app/tr.json'
import vi from '@panda/strings/app/vi.json'
import zh from '@panda/strings/app/zh_CN.json'
import I18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

I18n.use(LanguageDetector).init({
  fallbackLng: 'en',
  debug: process.env.REACT_APP_ENV === 'development',
  resources: {
    ar: { translation: ar },
    de: { translation: de },
    en: { translation: en },
    es: { translation: es },
    fr: { translation: fr },
    hi: { translation: hi },
    id: { translation: id },
    ja: { translation: ja },
    ko: { translation: ko },
    ms: { translation: ms },
    pt: { translation: pt },
    ru: { translation: ru },
    th: { translation: th },
    tr: { translation: tr },
    vi: { translation: vi },
    zh: { translation: zh },
  },
})

export { I18n }
